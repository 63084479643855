import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import { fonts } from '../theme/type';
import { uppercase, richTextStyles } from '../styles/type';
import { rem } from '../utilities/style';
import { media } from '../theme/media';

const Header = styled.div`
  position: relative;
`;

const image = css`
  aspect-ratio: 2/3;
  ${media('>small')} {
    aspect-ratio: 1440 / 900;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Title = styled.h1`
  font-family: ${fonts.domaine};
  font-size: ${rem(120)};
  letter-spacing: -.08em;

  ${media('>small')} {
    font-size: ${rem(286)};
  }
`;

const Text = styled.p`
  ${uppercase}
  ${richTextStyles}
  letter-spacing: 0em;
  font-size: ${rem(21)};
  margin-bottom: ${rem(16)};

  ${media('>small')} {
    font-size: ${rem(36)};
  }

  a {
    background-size: 100% .5rem;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <Header>
      <StaticImage
        src="../images/404.jpg"
        alt="404"
        layout="fullWidth"
        css={image}
      />
      <Content>
        <Title>404</Title>
        <Text>
          Whoa, what are you doing here?
        </Text>
        <Text>
          Get me &nbsp;
          <a href="/">outta’ here.</a>
        </Text>
      </Content>
    </Header>
  </Layout>
);

export default NotFoundPage;
